import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import MyPopup from "../../components/myPopup/myPopup";

import AddImage from "../../assets/svgs/addImage.svg";
import loadingUpdate from "../../assets/loading_trasnparent.gif";
import CrossButton from "../../assets/svgs/crossButton.svg";
import BackIconForm from "../../assets/svgs/backIconForm.svg";
import PlusIcon from "../../assets/svgs/plusIcon.svg";
import {
  getVimeoIDfromURL,
  getYoutubeIDfromURL,
} from "../../UserServices/Services";
import { updateMessage, updateOpen } from "../../redux/message";
import {
  artistMotionDeleteByAdmin,
  artistMotionGIFUpdateByAdmin,
  artistMotionVideoUpdateByAdmin,
  getSingleArtist,
} from "../../AxiosFunctions/Axiosfunctionality";

const MotionUpdate = ({ selectedArtist }) => {
  const dispatch = useDispatch();
  const [motions, setMotions] = useState(selectedArtist.motions.reverse());
  const [level, setLevel] = useState(0);
  const [title, setTitle] = useState();
  const [currentMotion, setCurrentMotion] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [motionType, setMotionType] = useState("none");
  const [videoUrl, setVideoUrl] = useState({
    videoKey: "",
    videoValue: "",
    videoID: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState({ videoError: false });
  const [loader, setLoader] = useState(false);
  const [mainimageUrl, setMainImageUrl] = useState();
  const [mainimageUpload, setMainImageUpload] = useState(null);
  const [typeOfUpload, setTypeOfUpload] = useState("");

  const videoUrlOptions = [
    { value: "YouTube", label: "YouTube" },
    // ...other options
  ];

  useEffect(() => {
    if (currentMotion) {
      if (motionType === "GIF") {
        setTitle(currentMotion.motionTitle);
        setMainImageUrl(currentMotion.motionImageUrl);
      } else if (motionType === "Video") {
        setTitle(currentMotion.motionTitle);
        setVideoUrl(currentMotion.motionVideoUrl);
      }
    }
  }, [currentMotion]);

  const handleTypeOfUpload = (event) => {
    if (event.target.value === "video") {
      setMainImageUpload(null);
      setMainImageUrl(null);
      setTitle("");
    }

    if (event.target.value === "gif") {
      setVideoUrl({
        videoKey: "",
        videoValue: "",
        videoID: "",
      });
      setTitle("");
    }

    setTypeOfUpload(event.target.value);
  };

  const handleLevelChange = (motion) => {
    setLevel(1);
    if (motion) {
      motion.motionImageUrl && setMotionType("GIF");
      motion.motionVideoUrl && setMotionType("Video");
      setCurrentMotion(motion);
    } else {
      setMotionType("Submit");
    }
  };

  const handleDeleteMotion = async (selectedMotion) => {
    try {
      await artistMotionDeleteByAdmin({
        _id: selectedMotion._id,
        artistId: selectedArtist._id,
      });
      const currentArt = await getSingleArtist({ _id: selectedArtist._id });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Deleted Successfully"));
      setDeleteModal(false);
      setLoader(false);
      setMotions(currentArt.motions);
    } catch (err) {
      dispatch(updateOpen(true));

      dispatch(updateMessage(err.message));
      setLoader(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setTitle(value);

    let fieldError = "";
    if (name === "title" && !value.trim()) {
      fieldError = "Title is required";
    }

    // Update validation errors state
    if (fieldError) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldError,
      }));
    } else {
      setValidationErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name]; // Remove the error entry if validation passes
        return updatedErrors;
      });
    }
  };

  const handleVideoUrlChange = (event) => {
    setVideoUrl((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const handleMainFileChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setMainImageUrl(null);
      setMainImageUpload(event.target.files[0]);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        mainImage: undefined,
      }));
    }
  };

  const handleBackButton = () => {
    setLevel(0);
    setCurrentMotion(undefined);
    setMotionType("none");
    setMainImageUpload(null);
    setMainImageUrl(null);
    setVideoUrl({
      videoKey: "",
      videoValue: "",
      videoID: "",
    });
    setTitle("");
  };
  const makeId = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };
  const handleVideoSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    let videoIDMessage;
    if (videoUrl.videoKey === "Vimeo") {
      videoIDMessage = getVimeoIDfromURL(videoUrl.videoValue);
    } else if (videoUrl.videoKey === "YouTube") {
      videoIDMessage = getYoutubeIDfromURL(videoUrl.videoValue);
    }
    if (videoIDMessage === "Invalid URL") {
      setError((prev) => {
        return { ...prev, videoError: true };
      });

      setLoader(false);
      return;
    } else {
      setError((prev) => {
        return { ...prev, videoError: false };
      });
    }
    let videoID;
    if (videoUrl.videoKey === "Vimeo") {
      videoID = "https://player.vimeo.com/video/" + videoIDMessage;
    } else if (videoUrl.videoKey === "YouTube") {
      videoID = "https://www.youtube.com/embed/" + videoIDMessage;
    }

    // Prepare the body of the request
    const formData = new FormData();
    formData.append("motionId", currentMotion?.motionId || makeId(24));
    formData.append("artistId", selectedArtist._id);
    formData.append("updateType", "motion");
    formData.append("motionTitle", title);
    formData.append(
      "motionVideoUrl",
      JSON.stringify({ ...videoUrl, videoID: videoID || "" })
    ); // Assuming socialMedia is an object

    try {
      await artistMotionVideoUpdateByAdmin(formData);
      const currentArt = await getSingleArtist({ _id: selectedArtist._id });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Saved Successfully"));
      setLoader(false);
      setMotions(currentArt.motions);
      handleBackButton();
    } catch (error) {
      dispatch(updateOpen(true));
      dispatch(updateMessage(error.message));
      setLoader(false);
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!title.trim()) errors.title = "Title is required";
    if (!mainimageUrl && !mainimageUpload)
      errors.mainImage = "Main image is required";
    setValidationErrors(errors);

    // Return true if no errors, false otherwise
    return Object.keys(errors).length === 0;
  };

  const handleGIFSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    if (!validateForm()) {
      setLoader(false);
      return;
    }

    const formData = new FormData();
    formData.append("artistId", selectedArtist._id);
    formData.append("motionId", currentMotion?.motionId || makeId(24));

    formData.append("updateType", "motion");
    mainimageUpload && formData.append("motionImageUpload", mainimageUpload); // Append the main image
    mainimageUrl && formData.append("motionImageUrl", mainimageUrl); // Append the main image
    formData.append("motionTitle", title);

    try {
      await artistMotionGIFUpdateByAdmin(formData);
      const currentArt = await getSingleArtist({ _id: selectedArtist._id });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Saved Successfully"));
      setLoader(false);
      setMotions(currentArt.motions);
      handleBackButton();
    } catch (error) {
      dispatch(updateOpen(true));
      dispatch(updateMessage(error.message));
      setLoader(false);
    }
  };

  const getSrc = (motion) => {
    let id = "";
    if (motion.motionVideoUrl) {
      id =
        motion.motionVideoUrl.videoID.split("/")[
          motion.motionVideoUrl.videoID.split("/").length - 1
        ];
    }
    return (
      motion.motionImageUrl ||
      "https://img.youtube.com/vi/" + id + "/hqdefault.jpg"
    );
  };

  return (
    <>
      {level === 0 && (
        <div className="px-1 px-md-5 artist mb-5">
          <h4> MOTION </h4>
          <div className="_4cols-v2">
            <label
              className="artistcardAdmin w-inline-block addImageBtn"
              onClick={() => handleLevelChange(undefined)}
            >
              <img alt="" src={AddImage} className="addImage" />
            </label>
            {motions &&
              motions.map((motion, ind) => (
                <div
                  className="artistcardAdmin w-inline-block"
                  style={{ cursor: "pointer" }}
                  key={ind}
                >
                  <div
                    onClick={() => handleDeleteMotion(motion)}
                    className="crossSection"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12px"
                      height="12px"
                      viewBox="0 0 352 512"
                    >
                      <path
                        fill="grey"
                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                      />
                    </svg>
                  </div>
                  <img
                    onClick={() => handleLevelChange(motion)}
                    src={getSrc(motion)}
                    alt={motion.personalWorkTitle}
                    className="image"
                  />
                </div>
              ))}
          </div>
        </div>
      )}
      {level === 1 && (
        <>
          {motionType === "Video" && videoUrl && (
            <>
              <img
                onClick={handleBackButton}
                alt="back"
                src={BackIconForm}
                style={{ width: "40px", cursor: "pointer" }}
              />
              <form onSubmit={handleVideoSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="videoKey"
                    className="labelsBio"
                    style={{ marginBottom: "20px" }}
                  >
                    Video
                  </label>
                  <div className="inputContainer">
                    <label htmlFor="pw_title" className="labelsBio">
                      Title
                    </label>
                    <div className="socialMediaContainerBio">
                      <input
                        type="text"
                        placeholder="Title"
                        value={title}
                        name="title"
                        className="textField"
                        onChange={handleChange}
                      />
                    </div>
                    {validationErrors.title && (
                      <p style={{ color: "red" }}>{validationErrors.title}</p>
                    )}
                  </div>
                  <div className="socialMediaContainerBio">
                    <select
                      className="textField"
                      name="videoKey"
                      value={videoUrl.videoKey}
                      onChange={(e) => handleVideoUrlChange(e)}
                      style={{ width: "20%" }}
                    >
                      <option value="">Select</option>
                      {videoUrlOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <input
                      type="text"
                      name="videoValue"
                      placeholder="Link"
                      value={videoUrl.videoValue}
                      className="textField socialMediaWebLink"
                      onChange={(e) => handleVideoUrlChange(e)}
                    />
                  </div>
                  <div className="d-flex mt-4">
                    {loader ? (
                      <img
                        alt="loading"
                        src={loadingUpdate}
                        style={{ width: "40px" }}
                      />
                    ) : (
                      <button className="mx-1 myBtn active sm">APPROVE</button>
                    )}
                  </div>
                  {error.videoError && (
                    <h4 style={{ color: "red" }}>Invalid Url</h4>
                  )}
                </div>
              </form>
            </>
          )}
          {motionType === "GIF" && (
            <>
              <img
                onClick={handleBackButton}
                alt="back"
                src={BackIconForm}
                style={{ width: "40px", cursor: "pointer" }}
              />
              <form onSubmit={handleGIFSubmit}>
                <label htmlFor="pw_cover" className="labelsBio">
                  GIF
                </label>
                {mainimageUrl && mainimageUrl !== "null" ? (
                  <div className="imageView" style={{ width: "fit-content" }}>
                    <img
                      className="CrossButton"
                      alt="CrossButton"
                      src={CrossButton}
                      onClick={() => {
                        setMainImageUpload(null);
                        setMainImageUrl(null);
                      }}
                    />
                    <img
                      className="imageUploaded"
                      alt="PlusIcon"
                      src={mainimageUrl}
                    />
                  </div>
                ) : mainimageUpload ? (
                  <div className="imageView" style={{ width: "fit-content" }}>
                    <img
                      className="CrossButton"
                      alt="CrossButton"
                      src={CrossButton}
                      onClick={() => {
                        setMainImageUpload(null);
                      }}
                    />
                    <img
                      className="imageUploaded"
                      alt="PlusIcon"
                      src={URL.createObjectURL(mainimageUpload)}
                    />
                  </div>
                ) : (
                  <label className="uploadArtistImage">
                    <img alt="PlusIcon" src={PlusIcon} />
                    <h5>UPLOAD IMAGE</h5>
                    <input
                      hidden
                      type="file"
                      onChange={handleMainFileChangeImage}
                      accept="image/gif"
                    />
                  </label>
                )}
                <div className="inputContainer" style={{ marginTop: "30px" }}>
                  <label htmlFor="pw_title" className="labelsBio">
                    Title
                  </label>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Title"
                      value={title}
                      name="title"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                  {validationErrors.title && (
                    <p style={{ color: "red" }}>{validationErrors.title}</p>
                  )}
                </div>
                {validationErrors.mainImage && (
                  <p style={{ color: "red" }}>{validationErrors.mainImage}</p>
                )}
                <div className="d-flex mt-4">
                  {loader ? (
                    <img
                      alt="loading"
                      src={loadingUpdate}
                      style={{ width: "40px" }}
                    />
                  ) : (
                    <button className="mx-1 myBtn active sm">APPROVE</button>
                  )}
                </div>
              </form>
            </>
          )}
        </>
      )}
      {motionType === "Submit" && (
        <>
          <div className="bioArtistContainer" style={{ paddingLeft: "100px" }}>
            <h4 style={{ marginBottom: "20px", fontSize: "1.2rem" }}>MOTION</h4>
            <img
              onClick={handleBackButton}
              alt="back"
              src={BackIconForm}
              style={{ width: "40px", cursor: "pointer" }}
            />
            <select
              className="textField"
              name="typeOfUpload"
              value={typeOfUpload}
              onChange={(e) => handleTypeOfUpload(e)}
            >
              <option value="">Select</option>
              <option value="gif">GIF</option>
              <option value="video">VIDEO</option>
            </select>
            {typeOfUpload === "video" && (
              <form onSubmit={handleVideoSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="videoKey"
                    className="labelsBio"
                    style={{ marginBottom: "20px" }}
                  >
                    Video
                  </label>
                  <div className="inputContainer">
                    <label htmlFor="pw_title" className="labelsBio">
                      Title
                    </label>
                    <div className="socialMediaContainerBio">
                      <input
                        type="text"
                        placeholder="Title"
                        value={title}
                        name="title"
                        className="textField"
                        onChange={handleChange}
                      />
                    </div>
                    {validationErrors.title && (
                      <p style={{ color: "red" }}>{validationErrors.title}</p>
                    )}
                  </div>
                  <div className="socialMediaContainerBio">
                    <select
                      className="textField"
                      name="videoKey"
                      value={videoUrl.videoKey}
                      onChange={(e) => handleVideoUrlChange(e)}
                      style={{ width: "20%" }}
                    >
                      <option value="">Select</option>
                      {videoUrlOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <input
                      type="text"
                      name="videoValue"
                      placeholder="Link"
                      value={videoUrl.videoValue}
                      className="textField socialMediaWebLink"
                      onChange={(e) => handleVideoUrlChange(e)}
                    />
                  </div>
                  <div className="d-flex mt-4">
                    {loader ? (
                      <img
                        alt="loading"
                        src={loadingUpdate}
                        style={{ width: "40px" }}
                      />
                    ) : (
                      <button className="mx-1 myBtn active sm">SUBMIT</button>
                    )}
                  </div>
                  {error.videoError && (
                    <h4 style={{ color: "red" }}>Invalid Url</h4>
                  )}
                </div>
              </form>
            )}
            {typeOfUpload === "gif" && (
              <form onSubmit={handleGIFSubmit}>
                <label htmlFor="pw_cover" className="labelsBio">
                  GIF
                </label>
                {mainimageUrl && mainimageUrl !== "null" ? (
                  <div className="imageView" style={{ width: "fit-content" }}>
                    <img
                      className="CrossButton"
                      alt="CrossButton"
                      src={CrossButton}
                      onClick={() => {
                        setMainImageUpload(null);
                        setMainImageUrl(null);
                      }}
                      style={{ backgroundColor: "gray" }}
                    />
                    <img
                      className="imageUploaded"
                      alt="PlusIcon"
                      src={mainimageUrl}
                    />
                  </div>
                ) : mainimageUpload ? (
                  <div className="imageView" style={{ width: "fit-content" }}>
                    <img
                      className="CrossButton"
                      alt="CrossButton"
                      src={CrossButton}
                      onClick={() => {
                        setMainImageUpload(null);
                      }}
                      style={{ backgroundColor: "gray" }}
                    />
                    <img
                      className="imageUploaded"
                      alt="PlusIcon"
                      src={URL.createObjectURL(mainimageUpload)}
                    />
                  </div>
                ) : (
                  <label className="uploadArtistImage">
                    <img alt="PlusIcon" src={PlusIcon} />
                    <h5>UPLOAD IMAGE</h5>
                    <input
                      hidden
                      type="file"
                      onChange={handleMainFileChangeImage}
                      accept="image/gif"
                    />
                  </label>
                )}
                <div className="inputContainer" style={{ marginTop: "30px" }}>
                  <label htmlFor="pw_title" className="labelsBio">
                    Title
                  </label>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Title"
                      value={title}
                      name="title"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                  {validationErrors.title && (
                    <p style={{ color: "red" }}>{validationErrors.title}</p>
                  )}
                </div>
                {validationErrors.mainImage && (
                  <p style={{ color: "red" }}>{validationErrors.mainImage}</p>
                )}
                <div className="d-flex mt-4">
                  {loader ? (
                    <img
                      alt="loading"
                      src={loadingUpdate}
                      style={{ width: "40px" }}
                    />
                  ) : (
                    <button className="mx-1 myBtn active sm">SUBMIT</button>
                  )}
                </div>
              </form>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MotionUpdate;
