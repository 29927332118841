import { Fragment, useEffect, useState } from "react";

import BackIconForm from "../../../assets/svgs/backArrow.svg";
import AccessDenied from "../accessDenied";
import { interestedPropertyEmail } from "../../../AxiosFunctions/Axiosfunctionality";
import { Link, useParams, useHistory } from "react-router-dom";

const TabProperties = ({
  properties,
  access,
  artist,
  agentData,
  artistID,
  viewTabProperties,
}) => {
  const [level, setLevel] = useState(0);
  const [curretProperty, setCurretProperty] = useState();
  const [allProperties, setAllProperties] = useState([]);
  const [pwModal, setPwModal] = useState(false);
  const [carrouselIndex, setCarrouselIndex] = useState(0);
  const [currentCarrousel, setCurrentCarrousel] = useState();
  const [displayCss, setDisplayCss] = useState(0);
  const [lineCarrousel, setLineCarrousel] = useState(0);
  const [interestedModal, setInterestedModal] = useState(false);
  const { search } = useParams();
  const { pages } = useParams();
  const history = useHistory();

  const queryParams = new URLSearchParams(history.location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    if (search === "properties" && id && allProperties.length > 0) {
      const property = allProperties
        .flatMap((pws) => pws)
        .find((pw) => pw._id === id);
      setLevel(1);
      setCurretProperty(property);
    } else {
      setLevel(0);
      setCurretProperty(null);
    }
  }, [id, allProperties]);

  useEffect(() => {
    populateAll(properties);
  }, [properties]);

  const handleLevelChange = (level, property) => {
    window.scrollTo(0, 0);
    if (level === 1) {
      history.push(`/${pages}/${search}?id=${property._id}`);
    } else if (level === 0) {
      history.push(`/${pages}/${search}`);
    }
  };

  const populateAll = (properties) => {
    const categories = [
      ...new Set(properties.map((property) => property.categoryProperty)),
    ];
    let tempAllProperties = [];
    categories.forEach((category) => {
      let everyCategory = properties.filter(
        (property) => property.categoryProperty === category
      );
      tempAllProperties.push(everyCategory);
    });
    setAllProperties(tempAllProperties);
  };

  const moveCarrouselPopUp = (moveTo) => {
    const newIndex = carrouselIndex + moveTo;
    if (
      newIndex >=
      curretProperty.extraProperties[currentCarrousel].categoryValueProperty
        .length
    ) {
      setCarrouselIndex(0);
    } else if (newIndex < 0) {
      setCarrouselIndex(
        curretProperty.extraProperties[currentCarrousel].categoryValueProperty
          .length - 1
      );
    } else {
      setCarrouselIndex(newIndex);
    }
  };

  const moveCarrousel = (moveTo, index) => {
    const carrouselLengh =
      curretProperty.extraProperties[index].categoryValueProperty.length / 5;
    const newDisplayCarrousel = lineCarrousel + moveTo;

    if (newDisplayCarrousel >= carrouselLengh) {
      setLineCarrousel(0);
      setDisplayCss(0);
    } else if (newDisplayCarrousel < 0) {
      setLineCarrousel(carrouselLengh - 1);
      setDisplayCss((carrouselLengh - 1) * 14);
    } else {
      setLineCarrousel(newDisplayCarrousel);
      setDisplayCss(newDisplayCarrousel * 14);
    }
  };

  const handleCarousel = (i, index) => {
    setPwModal(true);
    setCurrentCarrousel(i);
    setCarrouselIndex(index);
  };

  const handleInterested = async () => {
    setInterestedModal(true);
    await interestedPropertyEmail({
      email: access.email,
      propertyTitle: curretProperty.titleProperty,
      artist,
    });
  };

  return (
    <>
      {access ? (
        <div style={{ width: "100%" }}>
          {level === 0 && (
            <>
              <h3 className="homeh3 pwh3sub" style={{ marginBottom: "15px" }}>
                SELECT PROPERTY
              </h3>
              <p
                style={{
                  marginBottom: "30px",
                  fontWeight: "300",
                  lineHeight: "1.5",
                  fontSize: "0.8rem",
                  color: "#a9a8a8",
                }}
              >
                Please contact Shannon Associates at 212.333.2551 or email
                justin@shannonassociates.com, if you have any questions about a
                story. The ideas displayed are the property of the artist. All
                rights reserved.
              </p>
              {allProperties.length !== 0 &&
                allProperties.map((categoryX, i) => (
                  <Fragment key={i}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                      }}
                    >
                      <h3 className="homeh3 pwh3">
                        {categoryX[0].categoryProperty}
                      </h3>
                      <div className="separatorPw"></div>
                    </div>
                    <div className="detail_card2 pwCategpryCont">
                      {categoryX.map((property, i) => {
                        const favUpdate = agentData?.favUpdates?.find(
                          (update) => update?.artistId === artistID
                        );
                        const isInProperties = favUpdate?.properties?.some(
                          (item) => item === property.id
                        );

                        return (
                          <div
                            key={i}
                            style={{
                              cursor: "pointer",
                              border: isInProperties
                                ? "4px solid #d16a27"
                                : "none",
                              boxShadow: isInProperties
                                ? "0px 2px 6px rgba(0, 0, 0, 0.15)"
                                : "auto",
                            }}
                            onClick={() => {
                              isInProperties && viewTabProperties(property.id);
                              handleLevelChange(1, property);
                            }}
                          >
                            <div className="detail_card6_h">
                              <img
                                className="image"
                                src={property.propertyMainImage}
                                alt={property.titleProperty}
                              />
                            </div>
                            <label className="pwLabel">
                              {property.titleProperty}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </Fragment>
                ))}
            </>
          )}
          {level === 1 && (
            <div className="main-container-awards">
              <div style={{ display: "flex", justifyContent: "end" }}>
                <img
                  onClick={() => handleLevelChange(0, null)}
                  alt="back"
                  src={BackIconForm}
                  style={{ width: "40px", cursor: "pointer" }}
                />
              </div>
              <div className="awardsWithImage">
                <div>
                  <img
                    src={curretProperty.propertyMainImage}
                    alt={curretProperty.titleProperty}
                  />
                </div>
                <div className="awardContainer">
                  {curretProperty.titleProperty !== "" && (
                    <>
                      <h4
                        className="pwh4"
                        style={{ fontSize: "1.5rem", fontWeight: "500" }}
                      >
                        Title:
                      </h4>
                      <h4
                        className="pwh4"
                        style={{ fontWeight: "500", fontSize: "1.5rem" }}
                      >
                        {curretProperty.titleProperty}
                      </h4>
                    </>
                  )}
                  {curretProperty.authorProperty !== "" && (
                    <>
                      <h4 className="pwh4">Author:</h4>
                      <p className="pwP">{curretProperty.authorProperty}</p>
                    </>
                  )}
                  {curretProperty.illustratorProperty !== "" && (
                    <>
                      <h4 className="pwh4">Illustrator:</h4>
                      <p className="pwP">
                        {curretProperty.illustratorProperty}
                      </p>
                    </>
                  )}
                  {curretProperty.descriptionProperty !== "" && (
                    <>
                      <h4 className="pwh4">Description:</h4>
                      <p className="pwP">
                        {curretProperty.descriptionProperty}
                      </p>
                    </>
                  )}
                  {curretProperty.synopsisProperty !== "" && (
                    <>
                      <h4 className="pwh4">Brief Synopsis:</h4>
                      <div
                        className="BioDetailDescriptionPortfolio"
                        dangerouslySetInnerHTML={{
                          __html: curretProperty.synopsisProperty,
                        }}
                        style={{
                          marginTop: "15px",
                          fontFamily: "'Roboto Condensed'",
                          fontWeight: "300",
                          fontSize: "1.06rem",
                        }}
                      ></div>
                    </>
                  )}
                  {curretProperty.similarTitlesProperty !== "" && (
                    <>
                      <h4 className="pwh4">Similar Titles:</h4>
                      <p className="pwP">
                        {curretProperty.similarTitlesProperty}
                      </p>
                    </>
                  )}
                </div>
                {curretProperty?.propertyPdf &&
                  curretProperty.propertyPdf.name !== null && (
                    <>
                      <a
                        style={{
                          fontSize: "16px",
                          color: "#ce651e",
                          gridColumn: "1/4",
                        }}
                        target="_blank"
                        download={`${curretProperty.titleProperty}`}
                        href={curretProperty?.propertyPdf.url}
                      >
                        Download Manuscript
                      </a>
                    </>
                  )}
                {curretProperty?.propertyDummyPdf &&
                  curretProperty.propertyDummyPdf.name !== null && (
                    <>
                      <a
                        style={{ fontSize: "16px", color: "#ce651e" }}
                        target="_blank"
                        download={`${curretProperty.titleProperty}`}
                        href={curretProperty?.propertyDummyPdf.url}
                      >
                        Download Dummy Book
                      </a>
                    </>
                  )}
              </div>
              {curretProperty.extraProperties[0].categoryValueProperty && (
                <div style={{ width: "100%", marginBottom: "30px" }}>
                  {curretProperty.extraProperties.map((extra, i) => {
                    //---------------------------------------------------------TEXT SECTION
                    if (extra.categoryKeyProperty === "text") {
                      return (
                        <div
                          key={i}
                          className="BioDetailDescriptionPortfolio"
                          dangerouslySetInnerHTML={{
                            __html: extra.categoryValueProperty,
                          }}
                          style={{ marginBottom: "30px", marginTop: "30px" }}
                        ></div>
                      );
                    }
                    //---------------------------------------------------------BANNER SECTION
                    if (extra.categoryKeyProperty === "banner") {
                      return (
                        <div key={i}>
                          <img src={extra.categoryValueProperty}></img>
                        </div>
                      );
                    }
                    //---------------------------------------------------------CARROUSEL SECTION
                    if (extra.categoryKeyProperty === "carousel") {
                      return (
                        <>
                          <div
                            style={{
                              height: "14vh",
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <img
                              src="/assets/images/left.png"
                              alt="leftArrow"
                              className="carouselArrow"
                              style={{ alignSelf: "center", cursor: "pointer" }}
                              onClick={() => moveCarrousel(-1, i)}
                            />
                            <div
                              style={{
                                height: "14vh",
                                width: "100%",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="pwSecImgCont"
                                style={{
                                  translate: `0px calc((${displayCss}vh + ${
                                    lineCarrousel * 20
                                  }px)*-1)`,
                                }}
                              >
                                {extra.categoryValueProperty.map(
                                  (img, index) => {
                                    return (
                                      <div
                                        key={index}
                                        onClick={() => handleCarousel(i, index)}
                                        style={{
                                          cursor: "pointer",
                                          backgroundImage: `url(${img})`,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          height: "14vh",
                                          backgroundPosition: "center",
                                        }}
                                      ></div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                            <img
                              src="/assets/images/right.png"
                              alt="rightArrow"
                              className="carouselArrow"
                              style={{ alignSelf: "center", cursor: "pointer" }}
                              onClick={() => moveCarrousel(1, i)}
                            />
                            {pwModal && (
                              <>
                                <div className="modalTutorial">
                                  <div className="containerModal2">
                                    <div className="modalCloseBtn">
                                      <button onClick={() => setPwModal(false)}>
                                        ×
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        maxHeight: "90vh",
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        maxWidth: "93%",
                                      }}
                                    >
                                      <img
                                        src="/assets/images/left.png"
                                        alt="leftArrow"
                                        style={{
                                          maxWidth: "40px",
                                          width: "6%",
                                          alignSelf: "center",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => moveCarrouselPopUp(-1)}
                                      />
                                      <img
                                        src={
                                          curretProperty.extraProperties[
                                            currentCarrousel
                                          ].categoryValueProperty[
                                            carrouselIndex
                                          ]
                                        }
                                        alt="secondaryImage"
                                        style={{
                                          maxHeight: "90vh",
                                          maxWidth: "85%",
                                        }}
                                      />
                                      <img
                                        src="/assets/images/right.png"
                                        alt="rightArrow"
                                        style={{
                                          maxWidth: "40px",
                                          width: "6%",
                                          alignSelf: "center",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => moveCarrouselPopUp(1)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              <p
                style={{
                  marginBottom: "30px",
                  fontWeight: "300",
                  lineHeight: "1.5",
                  fontSize: "0.8rem",
                  alignSelf: "flex-end",
                }}
              >
                If you are interested in hearing more about this title, or would
                like to request a two-week exclusive, please email:
                justin@shannonassociates.com
              </p>
              <button
                className="submit-button mr-md-4"
                style={{ alignSelf: "flex-end" }}
                onClick={handleInterested}
              >
                INTERESTED
              </button>

              {interestedModal && (
                <>
                  <div className="modalTutorial">
                    <div className="containerModal2">
                      <div className="modalCloseBtn">
                        <button onClick={() => setInterestedModal(false)}>
                          ×
                        </button>
                      </div>
                      <p>
                        Thank you for your interest, a representative will reach
                        out in the next 24 hours to follow up on your request.
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default TabProperties;
