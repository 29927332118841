import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  findSingleContact,
  getVimeoIDfromURL,
  getYoutubeIDfromURL,
} from "../../UserServices/Services";

import loadingUpdate from "../../assets/loading_trasnparent.gif";
import CrossButton from "../../assets/svgs/crossButton.svg";
import PlusIcon from "../../assets/svgs/plusIcon.svg";
import { updateMessage, updateOpen } from "../../redux/message";
import {
  artistMotionGIFApprove,
  artistMotionVideoApprove,
} from "../../AxiosFunctions/Axiosfunctionality";

const MotionApprove = ({
  setFormNo,
  artistUpdateId,
  artistUpdates,
  populateArtistUpdates,
}) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState();
  const [motionType, setMotionType] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState({ videoError: false });
  const [loader, setLoader] = useState(false);
  const [mainimageUrl, setMainImageUrl] = useState();
  const [mainimageUpload, setMainImageUpload] = useState(null);
  const [artistId, setArtistId] = useState();
  const [profileUpdateId, setProfileUpdateId] = useState();
  const [motionId, setMotionId] = useState();
  useEffect(() => {
    if (artistUpdateId) {
      let singleUpdate = findSingleContact(artistUpdates, artistUpdateId);
      setProfileUpdateId(singleUpdate._id);
      setArtistId(singleUpdate.artistId._id);
            
      setTitle(singleUpdate.motionTitle);
      setMotionId(singleUpdate.motionId);

      singleUpdate.motionImageUrl && setMotionType("GIF");
      singleUpdate.motionImageUrl &&
        setMainImageUrl(singleUpdate.motionImageUrl);
      singleUpdate.motionVideoUrl && setMotionType("Video");
      singleUpdate.motionVideoUrl && setVideoUrl(singleUpdate.motionVideoUrl);
    }
  }, [artistUpdateId]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setTitle(value);

    let fieldError = "";
    if (name === "title" && !value.trim()) {
      fieldError = "Title is required";
    }

    // Update validation errors state
    if (fieldError) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldError,
      }));
    } else {
      setValidationErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name]; // Remove the error entry if validation passes
        return updatedErrors;
      });
    }
  };
  const makeId = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };
  const handleVideoUrlChange = (event) => {
    setVideoUrl((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const videoUrlOptions = [
    { value: "YouTube", label: "YouTube" },
    // ...other options
  ];

  const handleMainFileChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setMainImageUrl(null);
      setMainImageUpload(event.target.files[0]);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        mainImage: undefined,
      }));
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!title.trim()) errors.title = "Title is required";
    if (!mainimageUrl && !mainimageUpload)
      errors.mainImage = "Main image is required";
    setValidationErrors(errors);

    // Return true if no errors, false otherwise
    return Object.keys(errors).length === 0;
  };

  const handleVideoSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    let videoIDMessage;
    if (videoUrl.videoKey === "Vimeo") {
      videoIDMessage = getVimeoIDfromURL(videoUrl.videoValue);
    } else if (videoUrl.videoKey === "YouTube") {
      videoIDMessage = getYoutubeIDfromURL(videoUrl.videoValue);
    }
    if (videoIDMessage === "Invalid URL") {
      setError((prev) => {
        return { ...prev, videoError: true };
      });

      setLoader(false);
      return;
    } else {
      setError((prev) => {
        return { ...prev, videoError: false };
      });
    }
    let videoID;
    if (videoUrl.videoKey === "Vimeo") {
      videoID = "https://player.vimeo.com/video/" + videoIDMessage;
    } else if (videoUrl.videoKey === "YouTube") {
      videoID = "https://www.youtube.com/embed/" + videoIDMessage;
    }

    // Prepare the body of the request
    const formData = new FormData();
    formData.append("artistId", artistId);
    formData.append("profileUpdateId", profileUpdateId);
    formData.append("motionTitle", title);
    formData.append("motionId", motionId || makeId(24));

    formData.append(
      "motionVideoUrl",
      JSON.stringify({ ...videoUrl, videoID: videoID || "" })
    );

    try {
      await artistMotionVideoApprove(formData);
      dispatch(updateOpen(true));
      dispatch(updateMessage("Saved Successfully"));
      setLoader(false);
      await populateArtistUpdates();
      setFormNo(0);
    } catch (error) {
      dispatch(updateOpen(true));
      dispatch(updateMessage(error.message));
      setLoader(false);
    }
  };

  const handleGIFSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    if (!validateForm()) {
      setLoader(false);
      return;
    }

    const formData = new FormData();
    formData.append("artistId", artistId);
    formData.append("profileUpdateId", profileUpdateId);
    formData.append("motionId", motionId || makeId(24));
    formData.append("motionTitle", title);
    mainimageUpload && formData.append("motionImageUpload", mainimageUpload); // Append the main image
    mainimageUrl && formData.append("motionImageUrl", mainimageUrl); // Append the main image

    try {
      await artistMotionGIFApprove(formData);
      dispatch(updateOpen(true));
      dispatch(updateMessage("Saved Successfully"));
      setLoader(false);
      await populateArtistUpdates();
      setFormNo(0);
    } catch (error) {
      dispatch(updateOpen(true));
      dispatch(updateMessage(error.message));
      setLoader(false);
    }
  };

  return (
    <div className="bioArtistContainer" style={{ paddingLeft: "100px" }}>
      <h4 style={{ marginBottom: "20px", fontSize: "1.2rem" }}>MOTION</h4>
      {motionType === "Video" && videoUrl && (
        <form onSubmit={handleVideoSubmit}>
          <div className="mb-4">
            <label
              htmlFor="videoKey"
              className="labelsBio"
              style={{ marginBottom: "20px" }}
            >
              Video
            </label>
            <div className="inputContainer">
              <label htmlFor="pw_title" className="labelsBio">
                Title
              </label>
              <div className="socialMediaContainerBio">
                <input
                  type="text"
                  placeholder="Title"
                  value={title}
                  name="title"
                  className="textField"
                  onChange={handleChange}
                />
              </div>
              {validationErrors.title && (
                <p style={{ color: "red" }}>{validationErrors.title}</p>
              )}
            </div>
            <div className="socialMediaContainerBio">
              <select
                className="textField"
                name="videoKey"
                value={videoUrl.videoKey}
                onChange={(e) => handleVideoUrlChange(e)}
                style={{ width: "20%" }}
              >
                <option value="">Select</option>
                {videoUrlOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              <input
                type="text"
                name="videoValue"
                placeholder="Link"
                value={videoUrl.videoValue}
                className="textField socialMediaWebLink"
                onChange={(e) => handleVideoUrlChange(e)}
              />
            </div>
            <div className="d-flex mt-4">
              {loader ? (
                <img
                  alt="loading"
                  src={loadingUpdate}
                  style={{ width: "40px" }}
                />
              ) : (
                <button className="mx-1 myBtn active sm">APPROVE</button>
              )}
            </div>
            {error.videoError && <h4 style={{ color: "red" }}>Invalid Url</h4>}
          </div>
        </form>
      )}
      {motionType === "GIF" && (
        <form onSubmit={handleGIFSubmit}>
          <label htmlFor="pw_cover" className="labelsBio">
            GIF
          </label>
          {mainimageUrl && mainimageUrl !== "null" ? (
            <div className="imageView" style={{ width: "fit-content" }}>
              <img
                className="CrossButton"
                alt="CrossButton"
                src={CrossButton}
                onClick={() => {
                  setMainImageUpload(null);
                  setMainImageUrl(null);
                }}
                style={{ backgroundColor: "gray" }}
              />
              <img
                className="imageUploaded"
                alt="PlusIcon"
                src={mainimageUrl}
              />
            </div>
          ) : mainimageUpload ? (
            <div className="imageView" style={{ width: "fit-content" }}>
              <img
                className="CrossButton"
                alt="CrossButton"
                src={CrossButton}
                onClick={() => {
                  setMainImageUpload(null);
                }}
                style={{ backgroundColor: "gray" }}
              />
              <img
                className="imageUploaded"
                alt="PlusIcon"
                src={URL.createObjectURL(mainimageUpload)}
              />
            </div>
          ) : (
            <label className="uploadArtistImage">
              <img alt="PlusIcon" src={PlusIcon} />
              <h5>UPLOAD IMAGE</h5>
              <input
                hidden
                type="file"
                onChange={handleMainFileChangeImage}
                accept="image/gif"
              />
            </label>
          )}
          <div className="inputContainer" style={{ marginTop: "30px" }}>
            <label htmlFor="pw_title" className="labelsBio">
              Title
            </label>
            <div className="socialMediaContainerBio">
              <input
                type="text"
                placeholder="Title"
                value={title}
                name="title"
                className="textField"
                onChange={handleChange}
              />
            </div>
            {validationErrors.title && (
              <p style={{ color: "red" }}>{validationErrors.title}</p>
            )}
          </div>
          {validationErrors.mainImage && (
            <p style={{ color: "red" }}>{validationErrors.mainImage}</p>
          )}
          <div className="d-flex mt-4">
            {loader ? (
              <img
                alt="loading"
                src={loadingUpdate}
                style={{ width: "40px" }}
              />
            ) : (
              <button className="mx-1 myBtn active sm">APPROVE</button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default MotionApprove;
