export const getDifferenceOfDates = (dateString1, dateString2) => {
  // Parse date strings into Date objects
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  // Calculate the difference in milliseconds
  const timeDifference = date2 - date1;

  // Convert milliseconds to days, hours, minutes, and seconds
  const millisecondsPerSecond = 1000;
  const millisecondsPerMinute = 60 * millisecondsPerSecond;
  const millisecondsPerHour = 60 * millisecondsPerMinute;
  const millisecondsPerDay = 24 * millisecondsPerHour;

  const daysDifference = Math.floor(timeDifference / millisecondsPerDay);

  return daysDifference;
};
