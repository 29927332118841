import { Fragment, useEffect, useState } from "react";
import AddImage from "../../assets/svgs/addImage.svg";
import PlusIcon from "../../assets/svgs/plusIcon.svg";
import AddMoreInArray from "../../assets/svgs/addMoreInArray.svg";
import CrossIcon from "../../assets/svgs/crossIcon.svg";
import CrossButton from "../../assets/svgs/crossButton.svg";
import BackIconForm from "../../assets/svgs/backIconForm.svg";
import loadingUpdate from "../../assets/loading_trasnparent.gif";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../redux/message";
import ReactQuill from "react-quill";
import {
  artistPWDeleteByAdmin,
  artistPWUpdateByAdmin,
  getSingleArtist,
} from "../../AxiosFunctions/Axiosfunctionality";

const defaultFormFields = {
  title: "",
  labelAuthor: "Author",
  author: "",
  illustrator: "",
  client: "",
  publishedDate: "",
  artistContributed: "",
  category: "",
  subCategory: "",
};
const defaultReview = {
  descriptiveWords: "",
  authorOrSoR: "",
  quoteReview: "",
  isStarReview: false,
  link: "",
};

const PWUpdate = ({ selectedArtist }) => {
  const [level, setLevel] = useState(0);
  const [pws, setPws] = useState(selectedArtist.publishedWorks);
  const [currentPw, setCurrentPw] = useState();
  const [loader, setLoader] = useState(false);
  const [mainimageUpload, setMainImageUpload] = useState(null);
  const [mainimageUrl, setMainImageUrl] = useState(null);
  const [secondaryImages, setSecondaryImages] = useState([]);
  const [secondaryImagesUrl, setSecondaryImagesUrl] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [validationErrors, setValidationErrors] = useState({});
  const {
    title,
    labelAuthor,
    author,
    illustrator,
    client,
    publishedDate,
    artistContributed,
    category,
    subCategory,
  } = formFields;
  const [reviews, setReviews] = useState([]);
  const dispatch = useDispatch();
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [pwId, setPwId] = useState();
  const validateForm = () => {
    let errors = {};
    if (!title.trim()) errors.title = "Title is required";
    if (!mainimageUrl && !mainimageUpload)
      errors.mainImage = "Main image is required";
    if (!category) errors.category = "Category is required";
    if (hasSubcategoires() && !subCategory)
      errors.subCategory = "Subcategory is required when applicable";

    setValidationErrors(errors);

    // Return true if no errors, false otherwise
    return Object.keys(errors).length === 0;
  };
  const categoriesOptions = [
    { value: "publishing", label: "Publishing" },
    { value: "advertising", label: "Advertising" },
    { value: "editorial", label: "Editorial" },
    { value: "medical", label: "Medical" },
    { value: "entertainment", label: "Entertainment" },
    { value: "other", label: "Other" },
  ];
  const subCategories = {
    publishing: [
      { value: "pictureBook", label: "Picture Book" },
      { value: "middleGrade", label: "Middle Grade" },
      { value: "youngAdult", label: "Young Adult" },
      { value: "graphicNovel", label: "Graphic Novel" },
      { value: "boardBook", label: "Board Book" },
      { value: "adultPublishing", label: "Adult Publishing" },
      { value: "novelty", label: "Novelty" },
      { value: "boxSet", label: "Box Set" },
      { value: "game", label: "Game" },
      { value: "selfHelp", label: "Self-Help" },
      { value: "earlyReader", label: "Early Reader" },
    ],
  };

  const labelAuthorOptionms = ["Author", "Art Director", "Company"];

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { color: ["black", "red", "blue", "green"] },
      ],
      ["link"],
      ["clean"],
    ],
  };

  useEffect(() => {
    setLevel(0);
    setCurrentPw(null);
  }, []);

  useEffect(() => {
    // Combine initial URLs and new file objects for display
    const updatedDisplayImages = [
      ...secondaryImagesUrl,
      ...secondaryImages.map((file) => URL.createObjectURL(file)),
    ];
    setDisplayImages(updatedDisplayImages);
  }, [secondaryImages, secondaryImagesUrl]);

  useEffect(() => {
    if (currentPw) {
      setFormFields({
        title: currentPw.titlePW || "",
        labelAuthor: currentPw.labelAuthorPW || "",
        author: currentPw.authorPW || "",
        illustrator: currentPw.illustratorPW || "",
        client: currentPw.clientPW || "",
        publishedDate: currentPw.publishedDatePW || "",
        artistContributed: currentPw.artistContributedPW || "",
        category: currentPw.categoryPW || "",
        subCategory: currentPw.subCategoryPW || "",
      });
      setMainImageUrl(currentPw.pwMainImage || null);
      if (currentPw.pwSecondaryImages) {
        // Set the main image URL

        // Set the secondary images URL, exclude the first element
        setSecondaryImagesUrl(currentPw.pwSecondaryImages || []);
        setDisplayImages(currentPw.pwSecondaryImages || []);
      }
      setReviews(currentPw.reviewsPW);
      setPwId(currentPw.id);
    } else {
      setFormFields(defaultFormFields);
      setDisplayImages([]);
      setMainImageUrl(null);
      setSecondaryImagesUrl([]);
      setMainImageUpload(null);
      setSecondaryImages([]);
      setReviews([]);
      setPwId(null);
    }
  }, [currentPw]);

  const handleMainFileChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setMainImageUpload(event.target.files[0]);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        mainImage: undefined,
      }));
    }
  };

  const handleOtherFileChangeImage = (event) => {
    const images = [];

    if (
      event.target.files &&
      displayImages.length + event.target.files.length <= 5
    ) {
      for (let i = 0; i < event.target.files.length; i++) {
        images.push(event.target.files[i]);
      }
      setSecondaryImages([...secondaryImages, ...images]);
    } else {
      dispatch(updateOpen(true));
      dispatch(updateMessage("Max number of images is 5"));
    }
  };

  const hasSubcategoires = () => {
    return category === "" ? false : subCategories[category];
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "category" && hasSubcategoires()) {
      setFormFields({ ...formFields, subCategory: "", [name]: value });
    } else {
      setFormFields({ ...formFields, [name]: value });
    }
    let fieldError = "";
    if (name === "title" && !value.trim()) {
      fieldError = "Title is required";
    } else if (name === "category" && !value) {
      fieldError = "Category is required";
    } else if (name === "subCategory" && hasSubcategoires() && !value) {
      fieldError = "Subcategory is required when applicable";
    }

    // Update validation errors state
    if (fieldError) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldError,
      }));
    } else {
      setValidationErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name]; // Remove the error entry if validation passes
        return updatedErrors;
      });
    }
  };

  const addMoreReviews = () => {
    setReviews([...reviews, defaultReview]);
  };

  const handleChangeReviews = (event, index) => {
    const { name, value } = event.target;
    if (name === "isStarReview") {
      const newValue = value === "false" ? true : false;
      const newReviews = [...reviews];
      newReviews[index] = { ...reviews[index], [name]: newValue };
      setReviews(newReviews);
    } else {
      const newReviews = [...reviews];
      newReviews[index] = { ...reviews[index], [name]: value };
      setReviews(newReviews);
    }
  };

  const handleDeleteReview = (index) => {
    const filteredReviews = reviews.filter((_, i) => i !== index);
    setReviews(filteredReviews);
  };

  const handleChangeText = (index, text) => {
    text = text !== "<p><br></p>" ? text : "";
    const newReviews = [...reviews];
    newReviews[index] = { ...reviews[index], quoteReview: text };
    setReviews(newReviews);
  };

  const handleDeletePw = async (publishedWork) => {
    try {
      await artistPWDeleteByAdmin({
        publishedWorkId: publishedWork.id,
        artistId: selectedArtist._id,
      });
      const currentArt = await getSingleArtist({ _id: selectedArtist._id });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Deleted Successfully"));
      setIsPopupShow(true);
      setLoader(false);
      setPws(currentArt.publishedWorks);
    } catch (err) {
      dispatch(updateOpen(true));

      dispatch(updateMessage(err.message));
      setLoader(false);
    }
  };

  const handleDeleteImage = (index) => {
    // Adjust logic if needed to handle deleting both types of images: URLs and file objects
    const isUrlImage = index < secondaryImagesUrl.length;
    if (isUrlImage) {
      // Handle deletion of already uploaded images by URL
      const updatedUrls = displayImages.filter((_, idx) => idx !== index);
      setDisplayImages(updatedUrls);
      const updatedUrlsSec = secondaryImagesUrl.filter(
        (_, idx) => idx !== index
      );
      setSecondaryImagesUrl(updatedUrlsSec);
      // Additional logic to update server or state about deleted image
    } else {
      // Handle deletion of newly selected images
      const newFileIndex = index - secondaryImagesUrl.length;
      const updatedFiles = secondaryImages.filter(
        (_, idx) => idx !== newFileIndex
      );
      setSecondaryImages(updatedFiles);
    }
  };

  const handleLevelChange = (publishedWork) => {
    setLevel(1);
    setCurrentPw(publishedWork);
  };

  const handleBackButton = () => {
    setLevel(0);
    setCurrentPw(null);
    setPwId(null);
  };

  const makeId = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    // Prepare the body of the request
    if (!validateForm()) {
      setLoader(false);
      return;
    }
    const formData = new FormData();

    formData.append("pwId", currentPw?.id || makeId(24));
    formData.append("artistId", selectedArtist._id);
    formData.append("updateType", "published works");
    mainimageUpload && formData.append("pwMainImageUpload", mainimageUpload); // Append the main image
    mainimageUrl && formData.append("pwMainImage", mainimageUrl); // Append the main image
    secondaryImages.forEach((file, index) => {
      formData.append(`pwSecondaryImagesUpload`, file); // Append secondary images
    });
    formData.append(`pwSecondaryImages`, JSON.stringify(secondaryImagesUrl)); // Append secondary images

    formData.append("titlePW", title);
    formData.append("authorPW", author);
    formData.append("labelAuthorPW", labelAuthor);
    formData.append("clientPW", client);
    formData.append("illustratorPW", illustrator);
    formData.append("publishedDatePW", publishedDate);
    formData.append("artistContributedPW", artistContributed);
    formData.append("categoryPW", category);
    formData.append("subCategoryPW", subCategory);
    formData.append("reviewsPW", JSON.stringify(reviews) || ""); // Assuming skills is an array or object

    // Send the request
    try {
      await artistPWUpdateByAdmin(formData);
      const currentArt = await getSingleArtist({ _id: selectedArtist._id });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Saved Successfully"));
      setIsPopupShow(true);
      setLoader(false);
      setPws(currentArt.publishedWorks);
      handleBackButton();
    } catch (err) {
      dispatch(updateOpen(true));

      dispatch(updateMessage(err.message));
      setLoader(false);
    }
  };

  return (
    <>
      {level === 0 ? (
        <div className="px-1 px-md-5 artist mb-5">
          <h4> PUBLISHED WORKS </h4>
          <div className="_4cols-v2">
            <label
              className="artistcardAdmin w-inline-block addImageBtn"
              onClick={() => handleLevelChange(null)}
            >
              <img alt="" src={AddImage} className="addImage" />
            </label>
            {pws
              ? [...pws].reverse().map((publishedWork, ind) => (
                  <div
                    className="artistcardAdmin w-inline-block"
                    style={{ cursor: "pointer" }}
                    key={ind}
                  >
                    <div
                      onClick={() => handleDeletePw(publishedWork)}
                      className="crossSection"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12px"
                        height="12px"
                        viewBox="0 0 352 512"
                      >
                        <path
                          fill="grey"
                          d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                        />
                      </svg>
                    </div>
                    <img
                      onClick={() => handleLevelChange(publishedWork)}
                      src={publishedWork.pwMainImage}
                      alt={publishedWork.title}
                      className="image"
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : (
        level === 1 && (
          <div className="bioArtistContainer" style={{ paddingLeft: "100px" }}>
            <h4 style={{ marginBottom: "10px", fontSize: "1.2rem" }}>
              PUBLISHED WORKS
            </h4>
            <img
              onClick={handleBackButton}
              alt="back"
              src={BackIconForm}
              style={{ width: "40px", cursor: "pointer" }}
            />
            <p
              style={{
                marginBottom: "30px",
                fontWeight: "300",
                lineHeight: "1.5",
                fontSize: "0.8rem",
              }}
            >
              We encourage you to upload projects you want to highlight to
              clients. This includes any published books, magazine articles, and
              advertising campaigns. It is a wonderful way to help clients see
              your artwork in a completed form.
            </p>
            <p
              style={{
                marginBottom: "30px",
                fontWeight: "500",
                lineHeight: "1.5",
                fontSize: "0.8rem",
                color: "#FF0000",
              }}
            >
              ***Please do not upload anything earlier than it’s publication
              date - you are liable, this rule is good to adhere to in all
              regards. DO NOT to show partial or completed work (or post about
              it) until the client announces and shows it online first.
            </p>
            <h4 style={{ marginBottom: "10px", fontSize: "1.2rem" }}>
              PROJECT INFORMATION
            </h4>
            <form onSubmit={handleSubmit}>
              <label htmlFor="pw_cover" className="labelsBio">
                COVER / MAIN IMAGE
              </label>
              <p
                style={{
                  marginBottom: "30px",
                  fontWeight: "300",
                  lineHeight: "1.5",
                  fontSize: "0.8rem",
                }}
              >
                If a published book, please upload the designed cover (with
                type) produced by the client. If a campaign, please use the
                final approved image designed for the campaign or article.
              </p>
              {mainimageUrl && mainimageUrl !== "null" ? (
                <div className="imageView" style={{ width: "fit-content" }}>
                  <img
                    className="CrossButton"
                    alt="CrossButton"
                    src={CrossButton}
                    onClick={() => {
                      setMainImageUpload(null);
                      setMainImageUrl(null);
                    }}
                    style={{ backgroundColor: "gray" }}
                  />
                  <img
                    className="imageUploaded"
                    alt="PlusIcon1"
                    src={mainimageUrl}
                  />
                </div>
              ) : mainimageUpload ? (
                <div className="imageView" style={{ width: "fit-content" }}>
                  <img
                    className="CrossButton"
                    alt="CrossButton"
                    src={CrossButton}
                    onClick={() => {
                      setMainImageUpload(null);
                    }}
                    style={{ backgroundColor: "gray" }}
                  />
                  <img
                    className="imageUploaded"
                    alt="PlusIcon"
                    src={URL.createObjectURL(mainimageUpload)}
                    style={{ backgroundColor: "gray" }}
                  />
                </div>
              ) : (
                <label className="uploadArtistImage">
                  <img alt="PlusIcon" src={PlusIcon} />
                  <h5>UPLOAD IMAGE</h5>
                  <input
                    hidden
                    type="file"
                    onChange={handleMainFileChangeImage}
                    accept="image/*"
                  />
                </label>
              )}
              {validationErrors.mainImage && (
                <p style={{ color: "red" }}>{validationErrors.mainImage}</p>
              )}
              <div className="publishWorkInputContainer">
                <div className="inputContainer">
                  <label htmlFor="pw_title" className="labelsBio">
                    Title
                  </label>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Title"
                      value={title}
                      name="title"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                  {validationErrors.title && (
                    <p style={{ color: "red" }}>{validationErrors.title}</p>
                  )}
                </div>
                <div className="inputContainer">
                  <select
                    className="textField"
                    name="labelAuthor"
                    value={labelAuthor}
                    onChange={handleChange}
                  >
                    <option value="">Select / None</option>
                    {labelAuthorOptionms.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Author"
                      value={author}
                      name="author"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="inputContainer">
                  <label htmlFor="pw_illustrator" className="labelsBio">
                    Illustrator
                  </label>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Illustrator"
                      value={illustrator}
                      name="illustrator"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="inputContainer">
                  <label htmlFor="pw_client" className="labelsBio">
                    Client
                  </label>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Client"
                      value={client}
                      name="client"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="inputContainer">
                  <label htmlFor="pw_publishedDate" className="labelsBio">
                    Published Date
                  </label>
                  <div className="socialMediaContainerBio">
                    <input
                      type="date"
                      placeholder="Published Date"
                      value={publishedDate}
                      name="publishedDate"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="inputContainer">
                  <label htmlFor="pw_artistContributed" className="labelsBio">
                    Work Artist Contributed (example: Cover illustration)
                  </label>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Artist Contributed"
                      value={artistContributed}
                      name="artistContributed"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="inputContainer">
                  <label htmlFor="pw_category" className="labelsBio">
                    Category
                  </label>
                  <div className="socialMediaContainerBio">
                    <select
                      className="textField"
                      name="category"
                      value={category}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {categoriesOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {validationErrors.category && (
                    <p style={{ color: "red" }}>{validationErrors.category}</p>
                  )}
                </div>
                <div className="inputContainer">
                  {hasSubcategoires() && (
                    <>
                      <label htmlFor="pw_subCategory" className="labelsBio">
                        Sub-Category
                      </label>
                      <div className="socialMediaContainerBio">
                        <select
                          className="textField"
                          name="subCategory"
                          value={subCategory}
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          {subCategories[category].map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      {validationErrors.subCategory && (
                        <p style={{ color: "red" }}>
                          {validationErrors.subCategory}
                        </p>
                      )}
                    </>
                  )}
                </div>

                <div className="inputContainer" style={{ width: "100%" }}>
                  <label htmlFor="pw_cover" className="labelsBio">
                    SUPPORTING IMAGES
                  </label>
                  <p
                    style={{
                      marginBottom: "30px",
                      fontWeight: "300",
                      lineHeight: "1.5",
                      fontSize: "0.8rem",
                    }}
                  >
                    You can upload up to 5 supporting images (Please adhere to
                    your contract with your client). They can be interiors of a
                    book (full color/ black and white/ sequential, etc.), or
                    additional images from the campaign/ article.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",

                      alignItems: "center",
                    }}
                  >
                    {displayImages.length < 5 && (
                      <label
                        className="uploadArtistImage"
                        style={{ marginRight: "5px" }}
                      >
                        <img alt="PlusIcon" src={PlusIcon} />
                        <h5>UPLOAD IMAGE</h5>
                        <p
                          style={{
                            marginBottom: "30px",
                            fontWeight: "300",
                            lineHeight: "1.5",
                            fontSize: "0.8rem",
                          }}
                        >
                          Max. 5 Images
                        </p>
                        <input
                          hidden
                          multiple
                          type="file"
                          onChange={handleOtherFileChangeImage}
                          accept="image/*"
                        />
                      </label>
                    )}

                    {displayImages.map((img, index) => (
                      <div
                        key={index}
                        className="imageView"
                        style={{
                          border: "1px black solid",
                          margin: " 4px",
                          height: "100%",
                        }}
                      >
                        <img
                          className="CrossButton"
                          alt="CrossButton"
                          src={CrossButton}
                          onClick={() => handleDeleteImage(index)}
                          style={{ backgroundColor: "gray" }}
                        />
                        <img
                          className="imageUploaded"
                          alt={`Uploaded content ${index}`}
                          src={img}
                        />
                      </div>
                    ))}
                  </div>

                  <p
                    style={{
                      marginBottom: "30px",
                      fontWeight: "300",
                      lineHeight: "1.5",
                      fontSize: "0.8rem",
                      color: "#FF0000",
                    }}
                  >
                    Please press submit button below.
                  </p>
                </div>

                <div className="separator" />
                <div className="publishWorkInputContainer">
                  <h4 style={{ marginBottom: "0px", fontSize: "1.2rem" }}>
                    REVIEWS
                  </h4>
                  <p
                    style={{
                      marginBottom: "30px",
                      fontWeight: "300",
                      lineHeight: "1.5",
                      fontSize: "0.8rem",
                    }}
                  >
                    Please include POSITIVE reviews or quotes associated with
                    the projects you upload - especially STARRED reviews and
                    articles. If you do not have any reviews (that is OK) - this
                    section will not appear.
                  </p>
                  {reviews.map(
                    (
                      {
                        descriptiveWords,
                        authorOrSoR,
                        quoteReview,
                        link,
                        isStarReview,
                      },
                      index
                    ) => (
                      <Fragment key={index}>
                        <div
                          className="addMoreInArray"
                          style={{ width: "100%" }}
                          onClick={() => handleDeleteReview(index)}
                        >
                          <img alt="CrossIcon" src={CrossIcon} />
                        </div>
                        <div className="inputContainer">
                          <label htmlFor="pw_DescWords" className="labelsBio">
                            Descriptive Words
                          </label>
                          <p
                            style={{
                              marginBottom: "30px",
                              fontWeight: "300",
                              lineHeight: "1.5",
                              fontSize: "0.8rem",
                            }}
                          >
                            Highlight a few words: (example: “Brilliant” or “A
                            Masterpiece”)
                          </p>
                          <div className="socialMediaContainerBio">
                            <input
                              type="text"
                              placeholder="Descriptive Words"
                              value={descriptiveWords}
                              name="descriptiveWords"
                              className="textField"
                              onChange={(e) => handleChangeReviews(e, index)}
                            />
                          </div>
                        </div>
                        <div
                          className="inputContainer"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <label htmlFor="pw_authorOrSoR" className="labelsBio">
                            Author or source of the review
                          </label>
                          <p
                            style={{
                              marginBottom: "30px",
                              fontWeight: "300",
                              lineHeight: "1.5",
                              fontSize: "0.8rem",
                            }}
                          ></p>
                          <div className="socialMediaContainerBio">
                            <input
                              type="text"
                              placeholder="Author or source of the review"
                              value={authorOrSoR}
                              name="authorOrSoR"
                              className="textField"
                              onChange={(e) => handleChangeReviews(e, index)}
                            />
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="labelsBio">
                            Check if this is a STAR review
                          </label>
                          <label style={{ display: "inline" }}>
                            <input
                              className="mr-2"
                              name="isStarReview"
                              type="checkbox"
                              value={isStarReview}
                              checked={isStarReview}
                              onClick={(e) => handleChangeReviews(e, index)}
                            />
                            Star Review
                          </label>
                        </div>
                        <div
                          className="inputContainer"
                          style={{ width: "100%" }}
                        >
                          <label htmlFor="pw_quoteReview" className="labelsBio">
                            Quote / Review
                          </label>
                          <p
                            style={{
                              marginBottom: "30px",
                              fontWeight: "300",
                              lineHeight: "1.5",
                              fontSize: "0.8rem",
                            }}
                          >
                            You can highlight a section of the review, pull out
                            a quote, or copy a review here. Just make sure you
                            have permission to use it, if it isn’t found
                            somewhere else online.
                          </p>
                          <div>
                            <ReactQuill
                              value={quoteReview}
                              onChange={(text) => handleChangeText(index, text)}
                              modules={modules}
                            />
                          </div>
                        </div>
                        <div
                          className="inputContainer"
                          style={{ marginTop: "60px" }}
                        >
                          <label htmlFor="pw_link" className="labelsBio">
                            Link
                          </label>
                          <div className="socialMediaContainerBio">
                            <input
                              type="text"
                              placeholder="Link"
                              value={link}
                              name="link"
                              className="textField"
                              onChange={(e) => handleChangeReviews(e, index)}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )
                  )}
                  <div
                    className="addMoreInArray"
                    style={{ width: "100%" }}
                    onClick={addMoreReviews}
                  >
                    <img alt="PlusIcon" src={AddMoreInArray} />
                  </div>
                </div>
              </div>

              <div class="col-12 d-flex justify-content-end">
                {loader ? (
                  <img
                    alt="loading"
                    src={loadingUpdate}
                    style={{ width: "40px" }}
                  />
                ) : (
                  <div>
                    <button type="submit" class="mx-1 ml-4 myBtn active sm">
                      {currentPw ? "UPDATE" : "SUBMIT"}
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        )
      )}
    </>
  );
};

export default PWUpdate;
