import React, { useEffect, useState } from "react";
import { getDifferenceOfDates } from "../../../utlils/getDifferenceOfDates";

const NewArtistRibbon = ({ date }) => {
  const [show, setShow] = useState(false);
  const today = new Date();
  useEffect(() => {
    const dateDiff = getDifferenceOfDates(date, today);
    if (dateDiff <= 179) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [date]);
  if (!show) {
    return null; // Don't render the ribbon if the date difference is greater than 179 days
  }
  return (
    <div className="ribbon-wrapper">
      <div className="ribbon">New Artist</div>
    </div>
  );
};

export default NewArtistRibbon;
