import { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import AddToMoodBoardButton from "../agentUser/addToMoodBoardButton";

const TabPersonalWorks = ({
  personalWorks,
  agentData,
  artistID,
  viewPersonalWorks,
}) => {
  const [allImages, setAllImages] = useState([]);
  const [carrouselModal, setCarrouselModal] = useState(false);
  const [carrouselIndex, setCarrouselIndex] = useState(0);
  const { search } = useParams();
  const { pages } = useParams();
  const history = useHistory();

  const queryParams = new URLSearchParams(history.location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    if (search === "personalworks" && id && personalWorks.length > 0) {
      const personalWork = personalWorks.find((pw) => pw.personalWorkId === id);
      if (personalWork.personalWorkSecondaryImages.length !== 0) {
        setAllImages([
          personalWork.personalWorkMainImage,
          ...personalWork.personalWorkSecondaryImages,
        ]);
      } else {
        setAllImages([personalWork.personalWorkMainImage]);
      }
      setCarrouselModal(true);
    } else {
      setAllImages([]);
      setCarrouselModal(false);
      setCarrouselIndex(0);
    }
  }, [id, personalWorks]);
  const handleOpenModal = (personalWork) => {
    history.push(`/${pages}/${search}?id=${personalWork.personalWorkId}`);
  };

  const handleCloseModal = () => {
    history.push(`/${pages}/${search}`);
  };

  const moveCarrousel = (moveTo) => {
    const newIndex = carrouselIndex + moveTo;
    if (newIndex >= allImages.length) {
      setCarrouselIndex(0);
    } else if (newIndex < 0) {
      setCarrouselIndex(allImages.length - 1);
    } else {
      setCarrouselIndex(newIndex);
    }
  };

  return (
    <div className="personalWorksMainContainer">
      {personalWorks.map((personalWork, ind) => {
        const favUpdate = agentData?.favUpdates?.find(
          (update) => update?.artistId === artistID
        );
        const isInPersonalWorks = favUpdate?.personalWorks?.some(
          (item) => item === personalWork.personalWorkId
        );

        return (
          <div
            key={ind}
            className="personalWorkContainer"
            style={{
              backgroundImage: `url(${personalWork.personalWorkMainImage})`,
              cursor: "pointer",
              border: isInPersonalWorks ? "4px solid #d16a27" : "none",
              boxShadow: isInPersonalWorks
                ? "0px 2px 6px rgba(0, 0, 0, 0.15)"
                : "auto",
            }}
            onClick={() => {
              isInPersonalWorks &&
                viewPersonalWorks(personalWork.personalWorkId);
              handleOpenModal(personalWork);
            }}
          >
            <div className="personalWorkTextCont">
              <p>{personalWork.personalWorkTitle.toUpperCase()}</p>
            </div>
          </div>
        );
      })}

      {carrouselModal && (
        <div className="modalTutorial">
          <div className="containerModal2">
            <div className="modalCloseBtn">
              <button onClick={handleCloseModal}>×</button>
            </div>
            <div
              style={{
                maxHeight: "90vh",
                display: "flex",
                position: "relative",
                justifyContent: "space-evenly",
              }}
            >
              <img
                src="/assets/images/left.png"
                alt="leftArrow"
                style={{
                  maxWidth: "40px",
                  width: "6%",
                  alignSelf: "center",
                  cursor: "pointer",
                }}
                onClick={() => moveCarrousel(-1)}
              />
              <img
                src={allImages[carrouselIndex]}
                alt="secondaryImage"
                style={{ maxHeight: "90vh", maxWidth: "85%" }}
              />
              <img
                src="/assets/images/right.png"
                alt="rightArrow"
                style={{
                  maxWidth: "40px",

                  width: "6%",
                  alignSelf: "center",
                  cursor: "pointer",
                }}
                onClick={() => moveCarrousel(1)}
              />
              {agentData._id && (
                <AddToMoodBoardButton
                  agentData={agentData}
                  artistImage={allImages[carrouselIndex]}
                  artistImageLow={allImages[carrouselIndex]}
                  artistID={artistID}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TabPersonalWorks;
