import AgentTabEdit from "./agentTabEdit";
import AgentTabFav from "./agentTabFav";
import AgentTabMoodBoard from "./agentTabMoodboard";
import { useHistory, useLocation } from "react-router-dom";
import AgentMoodBoardEdit from "./agentMoodBoardEdit";
import { useEffect, useState } from "react";
import {
  favoriteUpdatesOnOff,
  getFavoriteArtists,
  getMoodBoardsSharedWithMe,
} from "../../../AxiosFunctions/Axiosfunctionality";
import loadingImage from "../../../assets/loading_trasnparent.gif";
import AgentHome from "./agentHome";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { addCart, emptyCart } from "../../../redux/addToCart";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../../redux/message";
import { AgentDataAPI } from "../../../redux/agentSlice";

const AgentProfile = ({
  searchedAgentDetails,
  haveEditAccess,
  agentDetails,
}) => {
  const dispatch = useDispatch();
  const [sharedMoodBoards, setSharedMoodBoards] = useState([]);
  const [selectedMoodBoard, setSelectedMoodBoard] = useState(null);
  const [reverseMoodboards, setReverseMoodboards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [favoriteUpdatesOn, setFavoriteUpdatesOn] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState([
    { val: "Home", link: "/" },
    { val: "MyShannon", link: "./myshannon" },
  ]);
  const [selectedMoodBoardEditAccess, setSelectedMoodBoardEditAccess] =
    useState(false);
  const tabsAgent = [
    { name: "MyHome", path: "home", tooltip: "home" },
    { name: "MyFavorite Artists", path: "favoriteArtists", tooltip: "" },
    {
      name: "MyIdea Boards",
      path: "ideaBoard",
      tooltip: "Create some ideaboards",
    },
    {
      name: "MyShared Idea Boards",
      path: "sharedIdeaBoard",
      tooltip: "Share some of your ideas.",
    },
  ];
  const history = useHistory();
  const location = useLocation();

  // Function to parse query parameters
  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };
  // Get the 'activeTab' query parameter
  const activeTab = getQueryParam("activeTab");
  const moodBoardId = getQueryParam("ideaBoardId");

  useEffect(() => {
    const moodboards = [...searchedAgentDetails.moodBoards];
    const rMoodboards = moodboards.reverse();
    setReverseMoodboards(rMoodboards);
  }, [searchedAgentDetails.moodBoards]);

  useEffect(() => {
    if (isATab(activeTab)) {
      let route = [];
      const agentId = agentDetails?._id;

      switch (activeTab) {
        case "home":
          route = [
            { val: "SAHome", link: "/" },
            {
              val: "MyShannon",
              link: `/myshannon/${agentDetails._id}/?activeTab=home`,
            },
          ];
          break;
        case "favoriteArtists":
          route = [
            { val: "SAHome", link: "/" },
            {
              val: "MyShannon",
              link: `/myshannon/${agentDetails._id}/?activeTab=home`,
            },
            {
              val: mapActiveTab(activeTab),
              link: `/myshannon/${agentId}/?activeTab=favoriteArtists`,
            },
          ];
          break;
        case "ideaBoard":
          if (moodBoardId) {
            const currentMoodBoard = searchedAgentDetails.moodBoards.find(
              (m) => m._id === moodBoardId
            );
            route = [
              { val: "SAHome", link: "/" },
              {
                val: "MyShannon",
                link: `/myshannon/${agentDetails._id}/?activeTab=home`,
              },
              {
                val: mapActiveTab(activeTab),
                link: `/myshannon/${agentId}/?activeTab=ideaBoard`,
              },
              { val: currentMoodBoard.title, link: "" },
            ];
          } else {
            route = [
              { val: "SAHome", link: "/" },
              {
                val: "MyShannon",
                link: `/myshannon/${agentDetails._id}/?activeTab=home`,
              },
              { val: mapActiveTab(activeTab), link: "" },
            ];
          }
          break;
        case "sharedIdeaBoard":
          if (moodBoardId && selectedMoodBoard) {
            route = [
              { val: "SAHome", link: "/" },
              {
                val: "MyShannon",
                link: `/myshannon/${agentDetails._id}/?activeTab=home`,
              },
              {
                val: mapActiveTab(activeTab),
                link: `/myshannon/${agentId}/?activeTab=sharedIdeaBoard`,
              },
              { val: selectedMoodBoard.title, link: "" },
            ];
          } else {
            route = [
              { val: "SAHome", link: "/" },
              {
                val: "MyShannon",
                link: `/myshannon/${agentDetails._id}/?activeTab=home`,
              },
              { val: mapActiveTab(activeTab), link: "" },
            ];
          }
          break;
        default:
          break;
      }
      setBreadCrumbs(route);
    }
  }, [activeTab, moodBoardId, selectedMoodBoard]);

  const isATab = (route) => {
    let isTab = false;
    tabsAgent.forEach((tab) => {
      if (tab.path === route) {
        isTab = true;
      }
    });
    return isTab;
  };

  const mapActiveTab = (tab) => {
    switch (tab) {
      case "favoriteArtists":
        return "MyFavorite Artists";
      case "ideaBoard":
        return "MyIdea Boards";
      case "sharedIdeaBoard":
        return "MyShared Idea Boards";
      default:
        return "";
    }
  };

  const handleTab = (tab, moodBoardId = null, sharedByUserId = null) => {
    if (tab === "ideaBoard" || tab === "sharedIdeaBoard") {
      setLoading(true);
    }
    const agentId = sharedByUserId || agentDetails._id;
    let url = `/myshannon/${agentId}/?activeTab=${tab}`;

    if (moodBoardId) {
      url += `&ideaBoardId=${moodBoardId}`;
    }

    history.push(url);
    setTimeout(() => {
      setLoading(false); // Stop loading after the URL is updated
    }, 500); // Simulate a delay for URL update}
  };
  useEffect(() => {
    const checkAccess = setTimeout(() => {
      if (activeTab === "edit" && !haveEditAccess) {
        history.push(`/`);
      }
    }, 1000); // Delay for 1 second (1000 milliseconds)

    return () => clearTimeout(checkAccess); // Clear the timeout if the component unmounts or dependencies change
  }, [activeTab, haveEditAccess]);
  const fetchSharedIdeaBoards = async () => {
    try {
      const response = await getMoodBoardsSharedWithMe({
        userId: agentDetails._id,
      });
      setSharedMoodBoards(response.data.moodBoards);
    } catch (error) {
      console.error("Error fetching shared idea boards", error);
    }
  };
  useEffect(() => {
    if (agentDetails && agentDetails._id) {
      setFavoriteUpdatesOn(agentDetails.favoriteUpdatesOn || false);

      fetchSharedIdeaBoards();
    }
  }, [agentDetails]);
  useEffect(() => {
    if (
      agentDetails &&
      moodBoardId &&
      sharedMoodBoards.length > 0 &&
      activeTab === "sharedIdeaBoard"
    ) {
      const moodBoard = sharedMoodBoards.find((mb) => mb._id === moodBoardId);
      setSelectedMoodBoard(moodBoard);
      setSelectedMoodBoardEditAccess(
        haveEditAccess ||
          (moodBoard?.sharedEditEmails &&
            moodBoard.sharedEditEmails.includes(agentDetails.email))
      );
    }
  }, [moodBoardId, sharedMoodBoards, haveEditAccess, agentDetails]);

  const handleContact = async () => {
    dispatch(emptyCart());
    const response = await getFavoriteArtists({
      _id: searchedAgentDetails?._id,
    });
    response.favoriteArtists.forEach((favArt) => {
      dispatch(
        addCart({
          key: favArt._id,
          data: {
            id: favArt._id,
            Name: `${favArt.firstname} ${favArt.lastname}`,
          },
        })
      );
    });
  };
  const handleCheckBox = async () => {
    try {
      const updatedValue = !favoriteUpdatesOn;

      // Optimistically update the UI
      setFavoriteUpdatesOn(updatedValue);

      // Update on the backend
      await favoriteUpdatesOnOff({
        agentId: agentDetails._id,
        favoriteUpdatesOn: updatedValue,
      });

      dispatch(updateOpen(true));
      dispatch(
        updateMessage(
          `Successfully ${updatedValue ? "enabled" : "disabled"} updates.`
        )
      );
      await dispatch(AgentDataAPI({ _id: agentDetails._id }));
    } catch (error) {
      console.error("Error updating favorite updates:", error);

      // Optional: Revert toggle on failure
      setFavoriteUpdatesOn(favoriteUpdatesOn); // Roll back state if needed
      dispatch(updateOpen(true));
      dispatch(updateMessage("Failed to update favorite updates."));
    }
  };

  function renderTabContent(activeTab, moodBoardId) {
    switch (activeTab) {
      case "home":
        return (
          <AgentHome
            agentID={searchedAgentDetails?._id}
            haveEditAccess={haveEditAccess}
            agentDetails={agentDetails}
            handleCheckBox={handleCheckBox}
            favoriteUpdatesOn={favoriteUpdatesOn}
          />
        );
      case "edit":
        return (
          <AgentTabEdit
            agentDetails={searchedAgentDetails}
            haveEditAccess={haveEditAccess}
          />
        );
      case "favoriteArtists":
        return (
          <AgentTabFav
            agentID={searchedAgentDetails?._id}
            haveEditAccess={haveEditAccess}
            agentDetails={agentDetails}
            handleCheckBox={handleCheckBox}
            favoriteUpdatesOn={favoriteUpdatesOn}
          />
        );
      case "ideaBoard":
        return moodBoardId ? (
          <AgentMoodBoardEdit
            moodBoardId={moodBoardId}
            searchedAgentDetails={searchedAgentDetails}
            agentDetails={agentDetails}
            haveEditAccess={haveEditAccess}
            moodBoards={searchedAgentDetails.moodBoards}
            activeTab={activeTab}
          />
        ) : (
          <AgentTabMoodBoard
            handleTab={handleTab}
            activeTab={activeTab}
            searchedAgentDetails={searchedAgentDetails}
            agentDetails={agentDetails}
            haveEditAccess={haveEditAccess}
            moodBoards={reverseMoodboards}
          />
        );
      case "sharedIdeaBoard":
        return moodBoardId && selectedMoodBoard ? (
          <AgentMoodBoardEdit
            moodBoardId={selectedMoodBoard._id}
            searchedAgentDetails={searchedAgentDetails}
            agentDetails={agentDetails}
            haveEditAccess={selectedMoodBoardEditAccess}
            moodBoards={sharedMoodBoards}
            fetchSharedIdeaBoards={fetchSharedIdeaBoards}
            activeTab={activeTab}
          />
        ) : (
          <AgentTabMoodBoard
            handleTab={handleTab}
            activeTab={activeTab}
            searchedAgentDetails={searchedAgentDetails}
            agentDetails={agentDetails}
            haveEditAccess={false}
            moodBoards={sharedMoodBoards}
          />
        );
      default:
        return null;
    }
  }
  return (
    <div>
      <div style={{ border: "1px solid", marginBottom: "5px" }}></div>
      <div style={{ border: "3px solid" }}></div>
      {((agentDetails && agentDetails._id) || haveEditAccess) &&
        activeTab !== "edit" && (
          <h2 className="hMyshannon welcomeTo">
            Welcome to{" "}
            <span style={{ color: "rgb(250, 142, 55)" }}>MyShannon</span>
          </h2>
        )}
      <div className="agentDashboardGrid">
        {/* ROUTEPATH */}
        {haveEditAccess && (
          <div>
            {
              <div
                className={"d-flex"}
                style={{ marginBottom: "10px", width: "98.4%" }}
              >
                {breadCrumbs.map((obj, ind) => (
                  <p
                    key={ind}
                    className={
                      breadCrumbs.length === ind + 1
                        ? "breadCrumbs"
                        : "breadCrumbsActive"
                    }
                    onClick={
                      breadCrumbs.length === ind + 1
                        ? () => {}
                        : () => history.push(obj.link)
                    }
                    style={ind === 0 ? {} : { marginLeft: "5px" }}
                  >
                    {obj.val} {breadCrumbs.length === ind + 1 ? null : ">"}
                  </p>
                ))}
              </div>
            }
          </div>
        )}
        {((agentDetails && agentDetails._id) || haveEditAccess) &&
          activeTab !== "edit" && (
            <div className="myShannonNavDiv">
              <div className="myShannonNavButtons">
                {tabsAgent.map((tab, i) => (
                  <button
                    key={i}
                    title={tab.tooltip}
                    className={`allArtistsButtons ${
                      activeTab === tab.path ? "allArtistsButtonsActive" : ""
                    } `}
                    onClick={() => handleTab(tab.path)}
                  >
                    {tab.name}
                  </button>
                ))}
                <Link
                  className="myShannonContactLink"
                  onClick={handleContact}
                  to="/contact"
                >
                  <button className="allArtistsButtons">Contact</button>
                </Link>
              </div>
            </div>
          )}
        {loading ? (
          <div className="" style={{ width: "full", textAlign: "center" }}>
            <img alt="loading" src={loadingImage} style={{ width: "50px" }} />
          </div> // Display loader here under the tabs
        ) : (
          <>{renderTabContent(activeTab, moodBoardId)}</>
        )}
      </div>
    </div>
  );
};

export default AgentProfile;
